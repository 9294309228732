/** @format */
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Basic
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800;900&display=swap");

* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  background: #000;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    93.5deg,
    #f89947 0%,
    #e730a8 31.67%,
    #7327d7 66.07%,
    #0a76eb 100%
  );
}

body {
  user-select: none;
  scroll-behavior: smooth;
  background: url("./BG.svg") no-repeat center, #27272a !important;
  background-size: cover !important;
}
a {
  text-decoration: none !important;
}
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Navbar
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================

.navbar {
  background: rgba(7, 7, 7, 0.75);
  /* Neutral/Neutral 01 */

  border-bottom: 1px solid #2c3a4b;
  backdrop-filter: blur(25px);
  button {
    background: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    backdrop-filter: blur(12.5px);
    border-radius: 6px;
    padding: 8px 20px;
    border: none;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 170%;
      color: #fff;
    }
  }
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid #fff !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}
.hero-tabs {
  border-bottom: 0px !important;
  .nav-link {
    color: #545d69 !important;
  }
  .nav-item {
    border-radius: 6px;
    margin: 0px 10px;
    box-shadow: 0px 0px 10px 4px #fcc9f198;
    // background-color: rgb(184, 192, 220);
    border-radius: 6px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    text-transform: uppercase;
    color: #fff !important;
    &:hover {
      border: none !important;
      // background-color: rgb(184, 192, 220);
    }
    &:active {
      // background-color: rgb(184, 192, 220);
    }
  }
}

.nav-link {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 170%;
  transition: all 0.4s;
  position: relative;
  display: block;
  color: #6d7580 !important;
  letter-spacing: 0.2px !important;
}
.nav-item:hover .nav-link {
  // -webkit-text-fill-color: transparent;
  // -text-fill-color: transparent;
  // -webkit-background-clip: text;
  // background-clip: text;
  // background-image: linear-gradient(93.5deg,#f89947,#e730a8 31.67%,#7327d7 66.07%,#0a76eb);
  color: #fff;
}
.nav-item > .active {
  color: #fff !important;
  border-bottom: 3.5px solid #e730a8;
}
.nav-item .nav-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  height: 100%;
  width: 0%;
  border-bottom: 3.5px solid #e730a8;
  transition: all 0.4s;
}

.nav-item:hover .nav-link::after {
  width: 80%;
}
.nav-btn {
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 170%;
  padding: 6px 25px;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  transition: all 0.3s linear;
  background: linear-gradient(
    93.5deg,
    #f89947 0%,
    #e730a8 31.67%,
    #7327d7 66.07%,
    #0a76eb 100%
  );
  border-radius: 100px !important;
  color: #ffffff;

  &:hover {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transform: scaleX(1.1);
    box-shadow: 0px 5px 30px 0px rgba(73, 68, 68, 0.57) !important;
  }
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                              Footer
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.footer-bg {
  padding-top: 30px;
  margin-top: 100px;
  video {
    object-fit: cover;
    width: 100%;
    height: 310px;
    position: absolute;
    bottom: 2;
    left: 0%;
  }
  .footer-ab-content {
    position: absolute;
    backdrop-filter: blur(7px);
    width: 100% !important;
    background: linear-gradient(180deg, #000001 0%, rgba(0, 0, 1, 0.44) 100%);
    left: 0;
    // right: 0
    padding: 0px 40px;
  }
  h3 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-transform: uppercase;
    color: #dadee3;
  }
  li {
    a {
      font-family: "poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      color: #858c94;
    }
  }
  svg {
    color: #a5abb3;
  }
  hr {
    height: 2px;
    background-color: #a5abb3;
    margin-top: 8px;
  }
  p {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #545d69;
  }
}

.footer-img {
  margin-top: -80px;
  margin-bottom: 30px;
}
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Other section
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.modal-content {
  background: #3f3f46 !important;
  border: 1px solid #394452 !important;
}
.modal-header {
  border-bottom: 1px solid #444e5c !important;
}
.modal-footer {
  border-top: 1px solid #444e5c !important;
}
.modal-title {
  color: #a5abb3;

  text-align: center !important;
  font-size: 18px !important;
  line-height: 28px !important;
  letter-spacing: -0.36px !important;
}

.btn-close {
  fill: #fff !important;
  color: #fff !important;
}
.logo {
  margin-top: 60px;
  margin-bottom: 20px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */
    text-align: center;

    letter-spacing: 0.005em;

    /* Neutral/Neutral 05 */

    color: #858c94;
    margin-bottom: 1rem;
  }
  a {
    padding: 8px 16px;
    gap: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* identical to box height, or 24px */
    letter-spacing: 0.008em;

    /* Neutral/Neutral 05 */

    color: #858c94;
    /* Neutral/Neutral 05 */

    border: 0.5px solid #858c94;
    border-radius: 100px;
    svg {
      font-size: 20px;
    }
  }
  margin-bottom: 3rem;
}
.banner {
  // background: linear-gradient(
  //   111.68deg,
  //   rgba(17, 23, 40, 0.15) 7.59%,
  //   rgba(49, 82, 165, 0.3) 102.04%
  // ) !important;
  height: 100px !important;
  width: 100% !important;
}
.bar {
  margin: 0 auto;
  background: #3f3f46;
  max-width: 575px;
  border-radius: 100px;
  border: 1px solid;
}
.bar:hover {
  border: 1px solid #394452;
}
.bar:focus-within {
  border: 1px solid #394452;
  outline: none;
}
.searcher {
  height: 50px;
  border: none;
  max-width: 500px;
  font-size: 17px;
  font-weight: 500;
  outline: none;
  background: transparent;
  color: #a5abb3;
  padding-left: 30px;
}
.voice {
  height: 20px;
  position: relative;
  top: -2.5px;
  left: 18px;
  color: #858c94;
  cursor: pointer;
}

.image {
  // padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 20px;
  }
}
.buttons {
  button {
    background-image: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    border-radius: 100px;
    padding: 9px 24px;

    border: none;
    color: #fff;
    transition: all 0.2s linear !important;
    span {
      font-style: normal;
      font-size: 16px;
      line-height: 170%;
      text-align: center;
      letter-spacing: 0.01em;
    }
    &:hover {
      background-image: linear-gradient(
        93.5deg,
        #f89947 0%,
        #e730a8 31.67%,
        #7327d7 66.07%,
        #0a76eb 100%
      ) !important;
      transition: all 0.2s linear !important;
      span {
        background-image: linear-gradient(
          93.5deg,
          #ffff 0%,
          #ffff 31.67%,
          #ffff 66.07%,
          #ffff 100%
        );
        -webkit-transition: background-image 0.5s ease-out;
        -moz-transition: background 0.5s ease-out;
        -ms-transition: background 0.5s ease-out;
        -o-transition: background 0.5s ease-out;
        transition: background 0.5s ease-out;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
}



.btn-close {
  outline: none !important;
  box-shadow: none !important;
}

.loading-sec {
  height: 480px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loading {
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75)  1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75)  0 1.5em 0 0,
    rgba(255, 255, 255, 0.75)  -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.5) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.5) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75)  0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75)  1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75)  1.5em 0 0 0,
    rgba(255, 255, 255, 0.75)  1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75)  0 1.5em 0 0,
    rgba(255, 255, 255, 0.75)  -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75)  -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75)  -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75)  0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75)  1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.box {
  // box-shadow: 0px 5px 30px 0px rgb(214 215 216 / 57%) !important;
  border-radius: 12px !important;
  background: url("./BG.svg") no-repeat center, #36363b;
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #394452;

  input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding-left: 10px;
    color: #cccfd3;
    background: #27272a;
    box-shadow: none !important;
  }

  h4 {
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    text-transform: none;
    letter-spacing: 0;
    color: #858c94;
    text-align: center;
  }

  button {
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 170%;
    padding: 6px 25px;
    text-align: center;
    text-transform: uppercase;
    height: 45px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transition: all 0.3s linear;
    background: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    border-radius: 100px;
    color: #ffffff;

    &:hover {
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      transform: scaleX(1.1);
      box-shadow: 0px 5px 30px 0px rgba(73, 68, 68, 0.57) !important;
    }
  }
}

.center-box {
  max-width: 500px;

  h1 {
    font-weight: 600;
    font-style: normal;
    font-size: 36px;
    text-transform: none;
    letter-spacing: 0;
    line-height: 38px;
    color: #25d366;
  }

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-transform: none;
    letter-spacing: 0;
  }
}
